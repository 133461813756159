import {Entry} from 'contentful';

import {IProductVariantFields} from '../contentful';

import {GaEcommerceItem} from './events';
import {toGaEcommerceItem} from './toGaEcommerceItem';

export const variantToItems = (
  variant: Entry<IProductVariantFields>
): GaEcommerceItem[] => {
  return variant.fields.stripeProducts.map((stripeProduct) => {
    const {
      fields: {stripeProductId, name},
    } = stripeProduct;
    return toGaEcommerceItem({id: stripeProductId, name});
  });
};
