// see https://stripe.com/docs/currencies#zero-decimal
// Stripe provides price amounts in the lowest unit of a currency
// For zero decimal currencies (e.g. JPY), this means that a unit amount of 1000 would by 1000 JPY
// For non-zero decimal currencies, the unit_amount would be divided by 100 to yield the price (e.g. for USD, 1000 as a unit amount == $10 USD)
export const ZERO_DECIMAL_CURRENCIES = {
  BIF: true,
  CLP: true,
  DJF: true,
  GNF: true,
  JPY: true,
  KMF: true,
  KRW: true,
  MGA: true,
  PYG: true,
  RWF: true,
  UGX: true,
  VND: true,
  VUV: true,
  XAF: true,
  XOF: true,
  XPF: true,
} as const;

export const isZeroDecimalCurrency = (
  currency: string
): currency is keyof typeof ZERO_DECIMAL_CURRENCIES => {
  const uppercaseCurrency = currency.toUpperCase();
  return uppercaseCurrency in ZERO_DECIMAL_CURRENCIES;
};

/**
 * Convert a Stripe unit_amount into the amount specific to a currency.
 *
 * Stripe gives amounts in the lowest unit of a currency (e.g. cents).
 * Some currencies do not have a decimal amount, which means the unit_amount is already the correct amount
 *
 * Examples:
 * * unit_amount=1000, currency=USD => 10 (USD)
 * * unit_amount=1000, currency=JPY => 1000 (JPY)
 * @param unit_amount a Stripe price unit_amount
 * @param currency three-letter ISO code, case insensitive
 */
export const stripeAmountToCurrencyAmount = (
  unit_amount: number,
  currency: string
): number => {
  const amount = isZeroDecimalCurrency(currency)
    ? unit_amount
    : unit_amount / 100;

  return amount;
};
