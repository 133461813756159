// see https://stripe.com/docs/currencies#zero-decimal
// Stripe provides price amounts in the lowest unit of a currency
// For zero decimal currencies (e.g. JPY), this means that a unit amount of 1000 would by 1000 JPY
// For non-zero decimal currencies, the unit_amount would be divided by 100 to yield the price (e.g. for USD, 1000 as a unit amount == $10 USD)
export const ZERO_DECIMAL_CURRENCIES = {
  BIF: true,
  CLP: true,
  DJF: true,
  GNF: true,
  JPY: true,
  KMF: true,
  KRW: true,
  MGA: true,
  PYG: true,
  RWF: true,
  UGX: true,
  VND: true,
  VUV: true,
  XAF: true,
  XOF: true,
  XPF: true,
} as const;

const isZeroDecimalCurrency = (
  currency: string
): currency is keyof typeof ZERO_DECIMAL_CURRENCIES => {
  return currency in ZERO_DECIMAL_CURRENCIES;
};

export type AmountTransform = (n: number) => number;

// TODO get the amount using the stripe util from utils-js
// This version differs slightly from the utils-js version.
// Changes:
// 1) adding the amountTransform option and AmountTransform type, this also updated the test
export const createPriceFormatter = (locale: string, currency: string) => {
  return (
    unit_amount: number,
    amountTransform: AmountTransform = (n) => n
  ): string => {
    const amount = isZeroDecimalCurrency(currency.toUpperCase())
      ? unit_amount
      : unit_amount / 100;

    const transformedAmount = amountTransform(amount);

    const formatted = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency.toUpperCase(),
    }).format(transformedAmount);

    // we remove trailing 00 decimals, e.g. $4.00 becomes $4
    const withRemovedZeroDecimals = formatted.replace(/\D00(?=\D*$)/, '');
    return withRemovedZeroDecimals;
  };
};

export interface PriceFormatParams {
  unit_amount: number;
  currency: string;
  locale: string;
  transform?: AmountTransform;
}

/** Formats a unit_amount from a Stripe price object */
export const formatPrice = (params: PriceFormatParams): string => {
  const {currency, locale, unit_amount, transform} = params;
  const format = createPriceFormatter(locale, currency);
  return format(unit_amount, transform);
};
