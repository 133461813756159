import {GaEcommerceItem} from './events';

export const toGaEcommerceItem = ({
  id,
  name,
}: {
  id: string;
  name: string;
}): GaEcommerceItem => {
  return {
    item_id: id,
    item_name: name,
  };
};
