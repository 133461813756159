import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {dimensions as customDimensions} from 'src/styles';

const {
  sectionVerticalPadding,
  sectionSidePadding,
  sectionVerticalPaddingMobile,
  sectionSidePaddingMobile,
} = customDimensions;

export const FullWidthSection = styled.section`
  width: 100%;

  position: relative;
  padding: ${sectionVerticalPadding} ${sectionSidePadding};

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    padding: ${sectionVerticalPaddingMobile} ${sectionSidePaddingMobile};
  }
`;
