import {defineMessage, MessageDescriptor, useIntl} from 'react-intl';

import {RecurringPriceInterval} from '@stryd/models';

import {DEFAULT_LOCALE} from 'src/config/locales';
import {MoneyBag, RecurringMoneyBag} from 'src/types';
import {AmountTransform, formatPrice} from 'src/utils/stripe/formatPrice';

type PaymentIntervalRecord<T> = Record<RecurringPriceInterval, T>;

const intervalMessageDescriptors: PaymentIntervalRecord<MessageDescriptor> = {
  day: defineMessage({
    id: 'subscription.intervalDaily',
    defaultMessage: '{intervalCount, plural, one {day} other {# days}}',
  }),
  week: defineMessage({
    id: 'subscription.intervalWeekly',
    defaultMessage: '{intervalCount, plural, one {week} other {# weeks}}',
  }),
  month: defineMessage({
    id: 'subscription.intervalMonthly',
    defaultMessage: '{intervalCount, plural, one {month} other {# months}}',
  }),
  year: defineMessage({
    id: 'subscription.intervalYearly',
    defaultMessage: '{intervalCount, plural, one {year} other {# years}}',
  }),
};

const intervalAbbreviatedMessageDescriptors: PaymentIntervalRecord<MessageDescriptor> =
  {
    day: defineMessage({
      id: 'subscription.abbreviatedIntervalDaily',
      defaultMessage: '{intervalCount, plural, one {d} other {# d}}',
    }),
    week: defineMessage({
      id: 'subscription.abbreviatedIntervalWeekly',
      defaultMessage: '{intervalCount, plural, one {w} other {# w}}',
    }),
    month: defineMessage({
      id: 'subscription.abbreviatedIntervalMonthly',
      defaultMessage: '{intervalCount, plural, one {m} other {# m}}',
    }),
    year: defineMessage({
      id: 'subscription.abbreviatedIntervalYearly',
      defaultMessage: '{intervalCount, plural, one {y} other {# y}}',
    }),
  };

type IntervalFormatOption = 'short' | 'long';

const getIntervalMessages = (
  intervalOption: IntervalFormatOption
): PaymentIntervalRecord<MessageDescriptor> => {
  switch (intervalOption) {
    case 'long':
      return intervalMessageDescriptors;
    default:
      return intervalAbbreviatedMessageDescriptors;
  }
};

export const isPaymentInterval = (
  str: string
): str is RecurringPriceInterval => {
  return str in intervalMessageDescriptors;
};

export interface UsePriceFormatterOptions {
  locale: string;
  /**
   * Defines how recurring intervals appear. Default `'long'`.
   *
   * Options:
   * * `'short'`: $15/m
   * * `'long'`: "$15 / month"
   */
  intervalFormat?: IntervalFormatOption;
  includeCommitment?: boolean;
}

const defaultOptions: Required<UsePriceFormatterOptions> = {
  locale: DEFAULT_LOCALE,
  intervalFormat: 'long',
  includeCommitment: false,
};

export const usePriceFormatters = (opts: UsePriceFormatterOptions) => {
  const {locale, intervalFormat, includeCommitment} = {
    ...defaultOptions,
    ...opts,
  };
  const intl = useIntl();

  const formatOneTimePrice = (price: MoneyBag, transform?: AmountTransform) => {
    return formatPrice({...price, locale, transform});
  };

  const formatRecurringPrice = (price: RecurringMoneyBag) => {
    const intervalMessages = getIntervalMessages(intervalFormat);

    const interval = intl.formatMessage(intervalMessages[price.interval], {
      intervalCount: price.interval_count,
    });
    const formattedPrice = formatOneTimePrice(price);

    const pricePerInterval = intl.formatMessage(
      {
        id: 'subscription.pricePerInterval',
        defaultMessage: '{value}/{interval}',
      },
      {value: formattedPrice, interval}
    );

    if (includeCommitment && price.min_recurring_intervals) {
      const commitmentIntervalCount =
        price.interval_count * price.min_recurring_intervals;
      const intervalMessage = intl.formatMessage(
        intervalMessageDescriptors[price.interval],
        {intervalCount: commitmentIntervalCount}
      );

      const priceWithCommitment = intl.formatMessage(
        {
          id: 'subscription.priceWithCommitmentLength',
          defaultMessage:
            '{price} for next {timeSpan}. Charges start at purchase.',
        },
        {price: pricePerInterval, timeSpan: intervalMessage}
      );

      return priceWithCommitment;
    }

    return pricePerInterval;
  };

  const formatMembershipRecurringPrice = (price: RecurringMoneyBag) => {
    const intervalMessages = getIntervalMessages(intervalFormat);

    const interval = intl.formatMessage(intervalMessages[price.interval], {
      intervalCount: price.interval_count,
    });
    const formattedPrice = formatOneTimePrice(price);

    const pricePerInterval = intl.formatMessage(
      {
        id: 'subscription.pricePerInterval',
        defaultMessage: '{value}/{interval}',
      },
      {value: formattedPrice, interval}
    );

    return pricePerInterval;
  };

  const formatMembershipSetupPrice = (price: MoneyBag) => {
    const formattedPrice = formatOneTimePrice(price);

    const priceInitialCost = intl.formatMessage(
      {
        id: 'subscription.priceInitialCost',
        defaultMessage: '+ {value} initial cost for Stryd pod',
      },
      {value: formattedPrice}
    );

    return priceInitialCost;
  };

  const formatCommitmentPrice = (
    recurringPrice: RecurringMoneyBag,
    commitmentTotalCost: MoneyBag
  ) => {
    const intervalMessages = getIntervalMessages(intervalFormat);
    const intervalMessage = intervalMessages[recurringPrice.interval];
    const interval = intl.formatMessage(intervalMessage, {
      intervalCount: recurringPrice.interval_count,
    });
    const formattedPrice = formatOneTimePrice(recurringPrice);
    const commitmentIntervalCount =
      recurringPrice.interval_count * recurringPrice.min_recurring_intervals;
    const commitmentLength = intl.formatMessage(intervalMessage, {
      intervalCount: commitmentIntervalCount,
    });

    const pricePerInterval = intl.formatMessage(
      {
        id: 'subscription.pricePerInterval',
        defaultMessage: '{value}/{interval}',
      },
      {value: formattedPrice, interval}
    );

    const commitmentPricing = intl.formatMessage(
      {
        id: 'subscription.commitmentLifetimePrice',
        defaultMessage: '{price} for {timeSpan} ({totalCost} lifetime)',
      },
      {
        price: pricePerInterval,
        timeSpan: commitmentLength,
        totalCost: formatOneTimePrice(commitmentTotalCost, Math.ceil),
      }
    );

    return commitmentPricing;
  };

  return {
    formatRecurringPrice,
    formatOneTimePrice,
    formatMembershipRecurringPrice,
    formatMembershipSetupPrice,
    formatCommitmentPrice,
  };
};
