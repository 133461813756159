import {regionConfigs} from 'src/config';
import {usePageRegionData} from 'src/contexts';
import {getVariantPricing} from 'src/features/pricing/helpers';
import {
  usePriceFormatters,
  UsePriceFormatterOptions,
} from 'src/hooks/usePriceFormatters';
import {IProductVariant} from 'src/utils/contentful/content-models/generated/contentful';

interface ProductVariantPriceProps
  extends Omit<UsePriceFormatterOptions, 'locale'> {
  variant: IProductVariant;
  style?: React.CSSProperties;
  quantity?: number;
}

export const ProductVariantPrice: React.FC<ProductVariantPriceProps> = (
  props
) => {
  const {variant, quantity = 1, style, ...formatOpts} = props;
  const {region, locale} = usePageRegionData();
  const {formatOneTimePrice, formatRecurringPrice} = usePriceFormatters({
    locale,
    ...formatOpts,
  });
  const {currency} = regionConfigs[region];

  const pricing = getVariantPricing({
    region,
    variant,
    currency,
  });

  const totalOneTimeCost = {
    ...pricing.oneTimePricesTotal,
    unit_amount: pricing.oneTimePricesTotal.unit_amount * quantity,
  };

  return (
    <span style={style}>
      {formatOneTimePrice(totalOneTimeCost)}
      {pricing.recurringPricesTotals.length > 0
        ? pricing.recurringPricesTotals.map((total) => (
            <span
              key={`${total.unit_amount}-${total.interval_count}-${total.interval}`}
            >
              {' '}
              +{'\xa0'}
              {/* non-breaking space so that the + and price stays on the same line */}
              {formatRecurringPrice(total)}
            </span>
          ))
        : null}
    </span>
  );
};
