import React from 'react';

import Script from 'next/script';

const KlaviyoEmbedLarge: React.FC = () => {
  return (
    <>
      <Script src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=RcYWM2" />
      <div className="klaviyo-form-R2dVmg" />
    </>
  );
};

export default KlaviyoEmbedLarge;
